import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  // {
  //   id: 'default-bay area',
  //   predictionPlace: {
  //     address: 'Bay Area, California',
  //     bounds: new LatLngBounds(new LatLng(38.0900, -121.8700), new LatLng(37.4200, -122.4600)),
  //   },
  // },
  {
    id: 'default-antioch',
    predictionPlace: {
      address: 'Antioch, California',
      bounds: new LatLngBounds(new LatLng(38.0672018, -121.732388), new LatLng(37.917962, -121.881042)),
    },
  },
  {
    id: 'default-berkeley',
    predictionPlace: {
      address: 'Berkeley, California',
      bounds: new LatLngBounds(new LatLng(37.906825, -122.214001), new LatLng(37.8412839, -122.3595275)),
    },
  },
  {
    id: 'default-lafayette',
    predictionPlace: {
      address: 'Lafayette, California',
      bounds: new LatLngBounds(new LatLng(37.97736054, -122.061491), new LatLng(37.81868713, -122.166921)),
    },
  },
  {
    id: 'default-martinez',
    predictionPlace: {
      address: 'Martinez, California',
      bounds: new LatLngBounds(new LatLng(38.0551022, -122.056918), new LatLng(37.9359451, -122.237219)),
    },
  },
  {
    id: 'default-oakland',
    predictionPlace: {
      address: 'Oakland, California',
      bounds: new LatLngBounds(new LatLng(37.868356, -122.074183), new LatLng(37.6315019, -122.3550716)),
    },
  },
  {
    id: 'default-orinda',
    predictionPlace: {
      address: 'Orinda, California',
      bounds: new LatLngBounds(new LatLng(37.961455, -122.139444), new LatLng(37.836211, -122.262781)),
    },
  },
  {
    id: 'default-san-leandro',
    predictionPlace: {
      address: 'San Leandro, California',
      bounds: new LatLngBounds(new LatLng(37.742499, -122.098607), new LatLng(37.6305468, -122.2250903)),
    },
  },
  {
    id: 'default-walnut-creek',
    predictionPlace: {
      address: 'Walnut Creek, California',
      bounds: new LatLngBounds(new LatLng(37.942687, -121.935254), new LatLng(37.842497, -122.100011)),
    },
  },
];

export default defaultLocations;
